<template>
	...
</template>

<script>
//import DnsLookupResultCellData from './DnsLookupResultCellData';

export default {
	name: 'IPLookupResult',
	props: {
		answers: Array,
	},
	components: {
		// ...
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
