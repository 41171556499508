<template>
	<form class="form-inline p-8 rounded bg-gray-300 space-y-8" @submit="execute">
		<div class="flex gap-8">
			<div class="form-group flex-grow">
				<label for="input-name">IP Address</label>
				<input type="search" name="ip" id="input-ip" class="form-control" :value="ip" placeholder="127.0.0.1">
			</div>
			
			<div class="flex-shrink-0 mt-auto">
				<button type="submit" class="btn btn-primary">Submit</button>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	name: 'IPLookupForm',
	props: {
		ip: String,
	},
	emits: {
		submit: ({ ip }) => {
				if (ip) {
					return true
				} else {
					console.warn('ip missing')
					return false
				}
			}
	},
	components: {
		// ...
	},
	methods: {
		execute(event) {
			event.preventDefault();

			const form = new FormData(event.target);
			const ip = form.get('ip');

			this.$emit('submit', {ip});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
