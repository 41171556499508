<template>
	<div class="ip-lookup">
		<div class="container space-y-4">
			<IPLookupForm :ip="$route.params.ip" @submit="performLookup"/>
			<IPLookupResult v-model:answers="queryAnswers"/>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import RCode from '@/lib/DnsRCodes';
import IPLookupForm from '@/components/IPLookupForm.vue'
import IPLookupResult from '@/components/IPLookupResult.vue'

export default {
	name: 'IP-Lookup',
	components: {
		IPLookupForm,
		IPLookupResult,
	},
	data() {
		return {queryAnswers: []}
	},
	methods: {
		performLookup(query) {
			axios.get(`https://ip-lookup.z4xh.workers.dev/?ip=${query.name}`).then((response) => {
				console.log(response);

				// Successful query
				if (response.data.Status === RCode.NOERROR && response.data.Answer) {
					this.queryAnswers = [];

					

					this.$emit('queryAnswers:update', this.queryAnswers)
				}
			});
		}
	}
}
</script>